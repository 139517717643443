import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { LanguageTableContent, PriceTableContent, Shipping_costs as ShippingCostsTableContent } from '../../models/edit-book.models';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { requiredFileType } from 'src/app/shared/validators/custom-validator.validator';
import { LicenseTypeService } from 'src/app/books/services/license-type.service';
import { ResponseHelper } from 'src/app/shared/helpers/response.helper';
import { LanguageService } from 'src/app/languages/services/language.service';
import { EditorialService } from 'src/app/editorials/services/editorial.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { ProductType } from '../../models/product-type.models';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CategoryRegister} from '../../models/category-product.models';
import { CategoryProductService } from '../../services/category-product.service';

class CategoryRegisterTemp {
  status: boolean;
  additionalData: any;
}
@Component({
  selector: 'app-edit-book-information',
  templateUrl: './edit-book-information.component.html',
  styleUrls: ['./edit-book-information.component.scss']
})
export class EditBookInformationComponent implements OnInit, AfterViewInit {
  CategoriesData: CategoryRegisterTemp[];
  @ViewChild('taxPercValue') taxPercValue: any;
  @ViewChild('inputAmount') inputAmount: any;
  @ViewChild('totalValue') totalValue: any;

  //Default options for form selects
  validAvailableLanguages: any;
  validAvailableCountries: any;
  validAvailableLicenses: any;
  productType: ProductType[] = ProductType.data;
  taxesCountries: { [key: string]: number } = {};
  currentTaxesPercentage: number = 0;
  currentTaxesAmount: number = 0;

  //Input book data
  productForm: FormGroup;
  languageForm: FormGroup;
  priceForm: FormGroup;
  shippingForm: FormGroup;
  formSubmitted: boolean = false;
  currentLang: string;


  constructor(private _formBuilder: FormBuilder,
    private _licenseTypeService: LicenseTypeService,
    private _languageService: LanguageService,
    private _editorialService: EditorialService,
    private _snackbarService: SnackbarService,
    private _pageLoadingService: PageLoadingService,
    private _translateService: TranslateService,
    private _category_productService : CategoryProductService,
    private _router: Router) { 
      this.currentLang =this._translateService.currentLang;

      this._translateService.onLangChange.subscribe(() => {
        this.currentLang =this._translateService.currentLang;
      });
  
    }

  initializeAvailableLanguages() {
    this._languageService.getAllLanguages().subscribe(data => {
      if (ResponseHelper.responseDontHaveErrors(data)) {
        this.validAvailableLanguages = data.data;
        
        
      }
      else {
        
      }
    });
  }

  initializeAvailableLicenses() {
    this._licenseTypeService.getAllServices().subscribe(data => {
      if (ResponseHelper.responseDontHaveErrors(data)) {
        this.validAvailableLicenses = data.data;
      }
      else {
        
      }
    });
  }

  getTranslatedName(name: string): string {
    // Reemplaza los espacios por guiones bajos
    return name.replace(/\s+/g, '_');
  }

  initializeAvailableCountries() {
    this._editorialService.getEditorialCountries().subscribe(data => {
      if (ResponseHelper.responseDontHaveErrors(data)) {
        this.validAvailableCountries = data.data;
        for (let i = 0; i < this.validAvailableCountries.length; i++) {
          const countryName = this.validAvailableCountries[i].countryInfo.name;
          const taxes = this.validAvailableCountries[i].countryInfo.taxes;
          this.taxesCountries[countryName] = taxes;
        }
      }
      else {
        
      }
    });
  }

  /**
   * @deprecated This function will be remove due client request taxes must be by product instead by country
   * @param country 
   */
  changeCountryTax(country: any) {
    this.currentTaxesPercentage = country.countryInfo.taxes;
    this.priceForm?.get('amount')?.setValue('');
    this.priceForm?.get('taxes')?.setValue('');
  }


  // Create function setCurrentTax
  setCurrentTax(taxes: any) {
    this.currentTaxesPercentage = this.taxPercValue.nativeElement.value;       
    this.getTotalPrice(this.inputAmount.nativeElement.value)

  }

  getTotalPrice(amount: any) {
    // let amount = document.getElementById()
    this.currentTaxesAmount = parseFloat(amount) * this.currentTaxesPercentage / 100;    
    let totalPrice = parseFloat(amount) + (parseFloat(amount) * this.currentTaxesPercentage / 100);
    let inputAmount = document.getElementById('taxes');
    // this.priceForm?.setValue(this.currentTaxesAmount);
    this.priceForm?.get('taxes')?.setValue(this.currentTaxesAmount);
    this.priceForm?.get('totalValue')?.setValue(totalPrice);
  }

  languageDisplayedColumns: string[] = ['index', 'language', 'title', 'shortDesc', 'actions'];
  languageDataSource = new MatTableDataSource<LanguageTableContent>([]);
  isUpdatingLanguage: boolean = false;
  previousLanguage: any;

  priceDisplayedColumns: string[] = ['index', 'country',  'amount', 'taxPercentage', 'taxes', 'totalValue', 'actions'];
  priceDataSource = new MatTableDataSource<PriceTableContent>([]);
  isUpdatingPrice: boolean = false;
  previousPriceCountry: any;


  shippingTableDisplayedColumns: string[] = ['weightTable_country', 
  'weightTable_weight', 'weightTable_unitWeight', 'weightTable_length', 'weightTable_height', 'weightTable_width', 'weightTable_unitLength','weightTable_price', 'actions'];
  shippingTableDataSource = new MatTableDataSource<ShippingCostsTableContent>([]);
  isUpdatingShippingCosts: boolean = false;
  previousShippingCosts: any;

  @ViewChild('languageTableSort')
  languageTableSort: MatSort;

  @ViewChild('languageTable')
  languageTable: MatTable<LanguageTableContent>;

  @ViewChild('priceTableSort')
  priceTableSort: MatSort;

  @ViewChild('shippingCostsTableSort')
  shippingCostsTableSort: MatSort;

  @ViewChild('priceTable')
  priceTable: MatTable<PriceTableContent>;


  @ViewChild('shippingCostsTable')
  shippingCostsTable: MatTable<ShippingCostsTableContent>;

  /*
  Language and amount fileds of respective tables data sources are complex objects.
  By this was necessary to implement sorting accesor for those:
  return the complex required property as simple value (sortingLanguageAccessor and sortinPriceAccessor).
  These functions for sorting are configured in ngOninit in sortingDataAccessor of each data source.
  */
  sortingLanguageAccessor = (item: { [key: string]: any }, property: string) => {
    switch (property) {
      case 'language': return (item as LanguageTableContent).language.name;
      default: return item[property];
    }
  };

  sortinPriceAccessor = (item: { [key: string]: any }, property: string) => {
    switch (property) {
      case 'country': return (item as PriceTableContent).country.countryInfo.name;
      default: return item[property];
    }
  };

  ngOnInit(): void {
    this.getAllCategories();
    this.languageDataSource.sortingDataAccessor = this.sortingLanguageAccessor;
    this.priceDataSource.sortingDataAccessor = this.sortinPriceAccessor;

    this.initializeAvailableLicenses();
    this.initializeAvailableLanguages();
    this.initializeAvailableCountries();

    this.productForm = this._formBuilder.group({
      productCode: ['', [Validators.required]],
      interactiveCode: ['', [Validators.required]],
      availability: ['', [Validators.required, Validators.min(1)]],
      ageRange: [''],
      licenseType: ['', Validators.required],
      selectedCategories: [[]],
      isPreview: [false],
      content: ['', [Validators.required, requiredFileType('zip')]],
      contentSource: ['', [Validators.required]],
      coverImage: ['', [Validators.required, requiredFileType('jpg')]],
      coverImageSource: ['', [Validators.required]],
      type: ['', [Validators.required]],
      bookLink: ['', [Validators.required]],
    });

    this.priceForm = this._formBuilder.group({
      'index': new FormControl(''),
      'amount': new FormControl('', [Validators.required, Validators.min(0)]),
      'taxes': new FormControl('', [Validators.required, Validators.min(0)]),
      'country': new FormControl('', [Validators.required]),
      'taxPercValue' : new FormControl(''),
      'totalValue' : new FormControl(''),
    });

    this.languageForm = this._formBuilder.group({
      'index': new FormControl(''),
      'language': new FormControl('', [Validators.required]),
      'title': new FormControl('', [Validators.required]),
      'shortDescription': new FormControl('', [Validators.required]),
      'resume': new FormControl('', [Validators.required])
    });

    this.shippingForm = this._formBuilder.group({
      'index': new FormControl(''),
      'country': new FormControl(''),
      'weight': new FormControl(''),
      'unit_weight': new FormControl(''),
      'length': new FormControl(''),
      'height': new FormControl(''),
      'width': new FormControl(''),
      'unit_sizes': new FormControl(''),
      'price': new FormControl('')
    }); 


  }

  ngAfterViewInit() {
    this.languageTableSort.disableClear = true;
    this.priceTableSort.disableClear = true;
  }

  getAllCategories() {
    this._category_productService.getCategory()
      .subscribe({
        next: (response) => {
          // Filtra las categorías que tengan isDelete igual a 0
          this.CategoriesData = (response.data as any[]).filter(category => category.isDelete === 0);
          
          // Solucionar el additionalData de cada categoría
          this.CategoriesData.forEach(category => {
            let temp = category.additionalData;
            category.additionalData = JSON.parse(temp);
          });
        },
        error: (error) => {
          console.error('Error al hacer la petición:', error);
        }
      });
  }

  hasCategorySelection(): boolean {
    return this.productForm.value.selectedCategories.length > 0;
  }
  onFileChange(event: any, formControlName: string) {
    

    if (event.target?.files?.length > 0) {
      const file = event.target?.files[0];
      const data: { [key: string]: File } = {};
      data[`${formControlName}Source`] = file;
      this.productForm.patchValue(data);

      const fileNameLabel = document.getElementById(`${formControlName}FileName`) as HTMLInputElement;
      fileNameLabel?.setAttribute('value', event.target?.files[0].name);
    }
  }

  addLanguageData() {

    const isValidlanguageForm = this.validateFormData(this.languageForm);

    if (!isValidlanguageForm)
      return;

    if (!this.isLanguageFilled(this.languageForm?.get('language')?.value.languageCode)) {
      const addLanguageFunction = () => {
        const newLanguageData: LanguageTableContent = {
          index: this.languageDataSource.data.length,
          language: this.languageForm?.get('language')?.value,
          resume: this.languageForm?.get('resume')?.value,
          shortDescription: this.languageForm?.get('shortDescription')?.value,
          title: this.languageForm?.get('title')?.value
        };

        this.languageDataSource.data.push(newLanguageData);
        this.languageTable.renderRows();
      };

      this.executeTableAction(this.languageDataSource, this.languageTableSort, addLanguageFunction);
    }
    else {
      this._snackbarService.openStandardSnackBar('addLanguageData', 'Ok');
    }
  }

  editLanguageData() {

    const isValidlanguageForm = this.validateFormData((this.languageForm as FormGroup));;

    if (!isValidlanguageForm)
      return;

    const selectedLanguageCode = this.languageForm?.get('language')?.value.languageCode;

    const isValidEdit = !this.isLanguageFilled(selectedLanguageCode) ||
      this.previousLanguage.languageCode == selectedLanguageCode;

    if (isValidEdit) {
      const editLanguageFunction = () => {
        const indexToUpdate = this.languageForm?.get('index')?.value;

        this.languageDataSource.data[indexToUpdate].language = this.languageForm?.get('language')?.value;
        this.languageDataSource.data[indexToUpdate].resume = this.languageForm?.get('resume')?.value;
        this.languageDataSource.data[indexToUpdate].shortDescription = this.languageForm?.get('shortDescription')?.value;
        this.languageDataSource.data[indexToUpdate].title = this.languageForm?.get('title')?.value;

        this.discardLanguageEdit();
      };

      this.executeTableAction(this.languageDataSource, this.languageTableSort, editLanguageFunction);
    }
    else {
      this._snackbarService.openStandardSnackBar('addLanguageData', 'Ok');
    }
  }

  removeLanguageData(index: number) {
    const removeLanguageFunction = () => {
      const indexToDelete = this.languageDataSource.data.findIndex(x => x.index == index);
      this.languageDataSource.data.splice(indexToDelete, 1);
      this.languageTable.renderRows();
    };

    this.executeTableAction(this.languageDataSource, this.languageTableSort, removeLanguageFunction);
  }

  /**
   * This function set language info to edit in form. 
   * @param index 
   */

  setLanguageFormToEdit(index: number) {
    this.isUpdatingLanguage = true;
    this.removeInvalidStyleFromForm(this.languageForm);

    const indexToUpdate = this.languageDataSource.data.findIndex(x => x.index == index);
    const elementToUpdate = this.languageDataSource.data[indexToUpdate];
    this.languageForm?.get('index')?.setValue(elementToUpdate.index);
    this.languageForm?.get('language')?.setValue(elementToUpdate.language);
    this.languageForm?.get('title')?.setValue(elementToUpdate.title);
    this.languageForm?.get('shortDescription')?.setValue(elementToUpdate.shortDescription);
    this.languageForm?.get('resume')?.setValue(elementToUpdate.resume);

    this.previousLanguage = elementToUpdate.language;
  }

  /**
   * function to limit range of ages
   */
  LimitValue(event: any) {
    const valor = event.target.value;
    if (valor > 12) {
      event.target.value = '12';
    }
  }

  /**
   * Function to set Shipping Cost in form to be edited
   */

  setShippingFormToEdit(index: number) {
    this.isUpdatingShippingCosts = true; 
  

    const indexToUpdate = this.shippingTableDataSource.data.findIndex(x => x.index == index);
    const elementToUpdate  = this.shippingTableDataSource.data[indexToUpdate]; 

    this.shippingForm?.get('index')?.setValue(elementToUpdate.index);
    this.shippingForm?.get('country')?.setValue(elementToUpdate.country);
    this.shippingForm?.get('weight')?.setValue(elementToUpdate.weight);
    this.shippingForm?.get('unitWeight')?.setValue(elementToUpdate.unit_weight);
    this.shippingForm?.get('length')?.setValue(elementToUpdate.length);
    this.shippingForm?.get('height')?.setValue(elementToUpdate.height);
    this.shippingForm?.get('width')?.setValue(elementToUpdate.width);
    this.shippingForm?.get('unitLenght')?.setValue(elementToUpdate.unit_sizes);
    this.shippingForm?.get('price')?.setValue(elementToUpdate.price);    
    
  }


  discardLanguageEdit() {
    this.isUpdatingLanguage = false;

    this.languageForm?.get('index')?.setValue('');
    this.languageForm?.get('language')?.setValue('default');
    this.languageForm?.get('title')?.setValue('');
    this.languageForm?.get('shortDescription')?.setValue('');
    this.languageForm?.get('resume')?.setValue('');

    this.removeInvalidStyleFromForm(this.languageForm);
  }

  isLanguageFilled(languageCode: string) {
    return this.languageDataSource.data.some(x => {
      return x.language.languageCode === languageCode;
    });
  }

  close(){
    window.location.href = '#/book/shop';
  }

  addPriceData() {
    this.formSubmitted = true;
    const isValidPriceForm = this.validateFormData(this.priceForm);

    if (!isValidPriceForm)
      return;

    if (!this.isPriceCountryFilled(this.priceForm?.get('country')?.value.countryInfo.countryCode)) {
      const addPriceFunction = () => {
        const newPriceData: PriceTableContent = {
          index: this.priceDataSource.data.length,
          amount: this.priceForm?.get('amount')?.value,
          taxes: this.priceForm?.get('taxes')?.value,
          taxPercentage: this.priceForm?.get('taxPercValue')?.value,
          country: this.priceForm?.get('country')?.value,
          totalValue: this.priceForm?.get('totalValue')?.value,          
        };

        this.priceDataSource.data.push(newPriceData);
        this.priceTable.renderRows();
      };

      this.executeTableAction(this.priceDataSource, this.priceTableSort, addPriceFunction);
    }
    else {
      this._snackbarService.openStandardSnackBar('addPriceData', 'Ok');
    }
  }


  editShippingData()
  {
    const isValidShippingForm = this.validateFormData(this.shippingForm);

    if (!isValidShippingForm)
      return;

    const selectedCountryCode = this.shippingForm?.get('country')?.value.countryInfo.countryCode;


    const editShippingFunction = () => {
      const indexToUpdate = this.shippingForm?.get('index')?.value;

      this.shippingTableDataSource.data[indexToUpdate].country = this.shippingForm?.get('country')?.value;  
      this.shippingTableDataSource.data[indexToUpdate].unit_weight = this.shippingForm?.get('unit_weight')?.value;
      this.shippingTableDataSource.data[indexToUpdate].weight = this.shippingForm?.get('weight')?.value;
      this.shippingTableDataSource.data[indexToUpdate].unit_sizes = this.shippingForm?.get('unit_sizes')?.value;
      this.shippingTableDataSource.data[indexToUpdate].length = this.shippingForm?.get('length')?.value;
      this.shippingTableDataSource.data[indexToUpdate].height = this.shippingForm?.get('height')?.value;
      this.shippingTableDataSource.data[indexToUpdate].width = this.shippingForm?.get('width')?.value;
      this.shippingTableDataSource.data[indexToUpdate].price = this.shippingForm?.get('price')?.value;

    }
    this.executeTableAction(this.shippingTableDataSource, this.shippingCostsTableSort, editShippingFunction);

    
  }

  editPriceData() {

    const isValidPriceForm = this.validateFormData(this.priceForm);

    if (!isValidPriceForm)
      return;

    const selectedCountryCode = this.priceForm?.get('country')?.value.countryInfo.countryCode;

    const isValidEdit = !this.isPriceCountryFilled(selectedCountryCode) ||
      this.previousPriceCountry.countryInfo.countryCode == selectedCountryCode;

    if (isValidEdit) {
      const editPriceFunction = () => {
        const indexToUpdate = this.priceForm?.get('index')?.value;

        this.priceDataSource.data[indexToUpdate].amount = this.priceForm?.get('amount')?.value;
        this.priceDataSource.data[indexToUpdate].taxes = this.priceForm?.get('taxes')?.value;
        this.priceDataSource.data[indexToUpdate].country = this.priceForm?.get('country')?.value;
        this.priceDataSource.data[indexToUpdate].taxPercentage = this.priceForm?.get('taxPercValue')?.value;
        this.priceDataSource.data[indexToUpdate].totalValue = this.priceForm?.get('totalValue')?.value;

        this.discardPriceEdit();
      };

      this.executeTableAction(this.priceDataSource, this.priceTableSort, editPriceFunction);
    }
    else {
      this._snackbarService.openStandardSnackBar('editPriceData', 'Ok');
    }
  }

  setPriceFormToEdit(index: number) {
    
    
    this.isUpdatingPrice = true;
    this.removeInvalidStyleFromForm(this.priceForm);

    const indexToUpdate = this.priceDataSource.data.findIndex(x => x.index == index);
    const elementToUpdate = this.priceDataSource.data[indexToUpdate];
    
    
    this.priceForm?.get('index')?.setValue(elementToUpdate.index);
    this.priceForm?.get('country')?.setValue(elementToUpdate.country);
    this.priceForm?.get('amount')?.setValue(elementToUpdate.amount);
    this.priceForm?.get('taxes')?.setValue(elementToUpdate.taxes);

    this.priceForm?.get('taxPercValue')?.setValue(elementToUpdate.taxPercentage);
    this.priceForm?.get('totalValue')?.setValue(elementToUpdate.totalValue);

    this.previousPriceCountry = elementToUpdate.country;
  }

  discardPriceEdit() {
    this.isUpdatingPrice = false;

    this.priceForm?.get('index')?.setValue('');
    this.priceForm?.get('country')?.setValue('default');
    this.priceForm?.get('amount')?.setValue('');
    this.priceForm?.get('taxes')?.setValue('');

    this.removeInvalidStyleFromForm(this.priceForm);
  }

  removePriceData(index: number) {
    const removePriceFunction = () => {
      const indexToDelete = this.priceDataSource.data.findIndex(x => x.index == index);
      this.priceDataSource.data.splice(indexToDelete, 1);
      this.priceTable.renderRows();
    };

    this.executeTableAction(this.priceDataSource, this.priceTableSort, removePriceFunction);
  }

  addShippingCostsData(){
    this.formSubmitted = true;
    const isValidShippingCostsForm = this.validateFormData(this.shippingForm);

    if (!isValidShippingCostsForm){
      
      return;
    }
    
    const addShippingCostsFunction = () => {
      const newShippingData: ShippingCostsTableContent = {
        index: this.shippingTableDataSource.data.length,
        country: this.shippingForm?.get('country')?.value.countryInfo.name,
        countryCode: this.shippingForm?.get('country')?.value.countryInfo.countryCode,
        weight: this.shippingForm?.get('weight')?.value,
        unit_weight: this.shippingForm?.get('unit_weight')?.value,
        length: this.shippingForm?.get('length')?.value,
        height: this.shippingForm?.get('height')?.value,
        width: this.shippingForm?.get('width')?.value,
        unit_sizes: this.shippingForm?.get('unit_sizes')?.value,
        price: this.shippingForm?.get('price')?.value
      };
      this.shippingTableDataSource.data.push(newShippingData);  
      this.shippingCostsTable.renderRows();      
    }; 

    this.executeTableAction(this.shippingTableDataSource, this.shippingCostsTableSort, addShippingCostsFunction);
    
  }


  /**
   * Remove a item of shipping costs table. 
   * @param index 
   */
  removeShippingData(index:number){
    const removeSizeFunction = () => {
      const indexToDelete = this.shippingTableDataSource.data.findIndex(x => x.index == index);
      this.shippingTableDataSource.data.splice(indexToDelete);
      this.shippingCostsTable.renderRows();
    }
    this.executeTableAction(this.shippingTableDataSource, this.shippingCostsTableSort, removeSizeFunction);
  }


  isPriceCountryFilled(countryCode: string) {
    return this.priceDataSource.data.some(x => {
      return x.country.countryInfo.countryCode === countryCode;
    });
  }

  getTotalCost(element: PriceTableContent): number {
    return element.amount + element.taxes;
  }

  private executeTableAction(dataSource: MatTableDataSource<any>, dataSort: MatSort, action: any) {
    /*Reason: The table wasn't refreshing when data source changing. It was caused by sort. By this reson we should
      disable the sort before update the table info, and before enable the sort again
    */
    this.disableDataSort(dataSource);

    action();

    this.enableDataSort(dataSource, dataSort);
  }

  private enableDataSort(dataSource: MatTableDataSource<any>, dataSort: MatSort) {
    dataSource.sort = dataSort;
  }

  private disableDataSort(dataSource: MatTableDataSource<any>) {
    dataSource.sort = null;
  }

  validateFormData(formGroup: FormGroup) {
    Object.entries(formGroup.controls).forEach(([key, value]) => {

      const controlElement = document.querySelector(`[formControlName=${key}]`);

      if ((value as AbstractControl).status == 'INVALID')
        controlElement?.classList.add('is-invalid');
      else
        controlElement?.classList.remove('is-invalid');
    });


    return formGroup.status == 'VALID';
  }

  removeInvalidStyleFromForm(formGroup: FormGroup) {
    Object.entries(formGroup.controls).forEach(([key, value]) => {

      const controlElement = document.querySelector(`[formControlName=${key}]`);
      controlElement?.classList.remove('is-invalid');
    });
  }

  generateFormdataForRequest() {
    let requestFormData = new FormData();
    let bookLanguages: { title: string; shortDescription: string; resume: string; languageCode: any; }[] = [];
    let bookPrices: { amount: number; taxes: number; country: any; currency: string; taxPercentage: number; totalValue: number; }[] = [];
    let bookShippingCosts: { weight: number; length: number; height: number; width: number; unit_weight: string; unit_sizes: string; price: number; countryCode: string; }[] = []; [] = []

    this.languageDataSource.data.forEach(lang => {
      bookLanguages.push({
        title: lang.title,
        shortDescription: lang.shortDescription,
        resume: lang.resume,
        languageCode: lang.language.languageCode
      });
    });

    this.priceDataSource.data.forEach(amount => {
      bookPrices.push({
        amount: amount.amount,
        taxes: amount.taxes,
        country: amount.country.countryInfo.countryCode,
        currency: amount.country.currency.name,
        taxPercentage: amount.taxPercentage,
        totalValue: amount.totalValue
      });
    });

    this.shippingTableDataSource.data.forEach(shipping => {
      bookShippingCosts.push({        
        weight: shipping.weight,
        length: shipping.length,
        height: shipping.height,
        width: shipping.width,
        unit_weight: shipping.unit_weight,
        unit_sizes: shipping.unit_sizes,
        price: shipping.price,
        countryCode: shipping.countryCode
      });
    }); 

    requestFormData.append('coverImage', this.productForm.get('coverImageSource')?.value);
    requestFormData.append('content', this.productForm.get('contentSource')?.value);
    //TODO: Replace editorial id by
    requestFormData.set('editorialId', '2');
    requestFormData.set('interactiveCode', this.productForm.get('interactiveCode')?.value);
    requestFormData.set('productCode', this.productForm.get('productCode')?.value);
    requestFormData.set('availability', this.productForm.get('availability')?.value);
    requestFormData.set('ageRange', this.productForm.get('ageRange')?.value);
    requestFormData.set('bookLink', this.productForm.get('bookLink')?.value);

    requestFormData.set('licenseType', this.productForm.get('licenseType')?.value);
    requestFormData.set('selectedCategories', this.productForm.get('selectedCategories')?.value);
    requestFormData.set('isPreview', this.productForm.get('isPreview')?.value);
    requestFormData.set('type', this.productForm.get('type')?.value);
    requestFormData.set('languages', JSON.stringify(bookLanguages));
    requestFormData.set('prices', JSON.stringify(bookPrices));
    requestFormData.set('shippingCosts', JSON.stringify(bookShippingCosts));

    return requestFormData;
  }

  validateLanguagesData() {
    let isSomeLanguageNotContained = false;

    this.validAvailableLanguages.forEach((validElement: any) => {
      this.languageDataSource.data.some((sourceElement) => {
        if (validElement.languageCode != sourceElement.language.languageCode)
          isSomeLanguageNotContained = true;
      });
    });

    return isSomeLanguageNotContained;
  }

  onSubmit() {
    this._pageLoadingService.showLoadingGif();

    if (!this.validateFormData(this.productForm)) {
      if (!this.validateLanguagesData()) {
        this._snackbarService.openStandardSnackBar('onSubmitInvalidFormData');
      }

      this._pageLoadingService.hideLoadingGif();
      return;
    }
    else {
      this._editorialService.createBook(this.generateFormdataForRequest()).subscribe((data) => {
        this._pageLoadingService.hideLoadingGif();
        this._router.navigate(['/book/my-books']);
        this._snackbarService.openStandardSnackBar('editBookOnSubmitSuccessfullyCreated', 'Ok');
      },
        (error) => {
          this._pageLoadingService.hideLoadingGif();
          this._snackbarService.openStandardSnackBar('editBookOnSubmitError', 'Ok');
        });
    }
  }
}
